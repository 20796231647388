.container-card{
    grid-area: contenedor;
    display: flex;
    flex-wrap: wrap;
    background-color: lightgray;
    
    
}

.lateral-buscador{
    /* display: ;
    align-items: center; */
    grid-area: buscador;
    /* flex-direction: column; */
    max-width: 300px;
    margin-right: 10px;
    margin-left: 10px;
}




.search-input{
    margin-top: 20px;
}

.lateral-buscador h2{
    font-weight: 500;
    line-height: 28.13px;
    font-size: 1.5rem;
    font-family: Roboto, sans-serif;
    color: rgb(11, 11, 11);    
}

.lateral-buscador h5{
    margin: 0px;
    line-height: 18.75px;
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    color: rgb(205, 80, 41);
    font-weight: 400;
    width: 80px;
    text-wrap: nowrap;
}



.card{
    
    margin: 10px;
}

.paginacion{
   
    grid-area: paginacion;
    display: flex;
    justify-content: center;
    align-items: center;
}


 .card-media {
    cursor:pointer;
    
}